import React, { useState, useEffect } from "react"
import "../style/Mainpanel.css"

import { useAppSelector, useAppDispatch } from "../app/hooks"

import { getEnlargedChart, setEnlargedChart } from "../features/UIState"
import {
  selectBatteryChartData,
  selectDailySumChartData,
  selectGridThroughputChartData,
  selectWattThroughputChartData,
} from "../features/Chart"

import "primereact/resources/themes/arya-blue/theme.css"
import "primeicons/primeicons.css"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Nullable } from "primereact/ts-helpers"
import { Chart } from "primereact/chart"
import { Knob } from "primereact/knob"
import { selectAllKnobData } from "../features/Knob"
/*
http://pepi-home.com/api/v1/current

http://pepi-home.com/api/v1/2024-11-24/battery
http://pepi-home.com/api/v1/2024-11-24/deficit
http://pepi-home.com/api/v1/2024-11-24/dailySum
http://pepi-home.com/api/v1/2024-11-24/wattThroughput
http://pepi-home.com/api/v1/2024-11-24/gridThroughput
http://pepi-home.com/api/v1/2024-11-24/ephemeris
*/

const MainPanel = () => {
  const dispatch = useAppDispatch()

  const batteryData = useAppSelector(selectBatteryChartData)
  const dailySumData = useAppSelector(selectDailySumChartData)
  const wattThroughputData = useAppSelector(selectWattThroughputChartData)
  const gridThroughputData = useAppSelector(selectGridThroughputChartData)

  const knobData = useAppSelector(selectAllKnobData)

  const [dates, setDates] = useState<Nullable<(Date | null)[]>>(null)

  const enlargedChart = useAppSelector(getEnlargedChart)
  const [selectedChart, setSelectedChart] = useState<Nullable<any>>(null)

  const documentStyle = getComputedStyle(document.documentElement)
  const textColor = documentStyle.getPropertyValue("--text-color")
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  )
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border")
  const options = {
    maintainAspectRatio: enlargedChart,
    aspectRatio: enlargedChart ? 3 : 1,

    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
  }

  useEffect(() => {
    console.log(knobData)
  }, [knobData])

  return (
    <div>
      {/*<div className="header">
        <header
          className="flex align-items-center justify-content-between"
          style={{ width: "100%", padding: "1rem", backgroundColor: "#1E1E1E" }}
        >
          <div className="flex align-items-center">
            <Button label="Button 1" className="p-button-secondary p-mr-2" />
            <Button
              label="Button 2"
              className="p-button-secondary p-mr-2"
              style={{ marginLeft: "1rem" }}
            />
            <Calendar
              value={dates}
              onChange={(e) => setDates(e.value)}
              selectionMode="range"
              readOnlyInput
              hideOnRangeSelection
              style={{ marginLeft: "1rem" }}
            />
            <Button
              label="x"
              className="p-button-secondary p-mr-2"
              onClick={() => setDates(null)}
            />
          </div>
        </header>
      </div> */}
      <div className="main-panel">
        {knobData && (
          <div className="knob-container">
            <div className="knob">
              <Knob
                value={knobData.batteryKnob.vaLue}
                min={knobData.batteryKnob.min}
                max={knobData.batteryKnob.max}
                size={80}
                valueColor={knobData.batteryKnob.valueColor}
                readOnly
              />
              <div>Battery</div>
            </div>
            <div className="knob">
              <Knob
                value={knobData.autonomyKnob.vaLue}
                min={knobData.autonomyKnob.min}
                max={knobData.autonomyKnob.max}
                size={80}
                valueColor={knobData.autonomyKnob.valueColor}
                readOnly
              />
              <div>Autonomy</div>
            </div>
            <div className="knob">
              <Knob
                value={knobData.consumptionKnob.vaLue}
                min={knobData.consumptionKnob.min}
                max={knobData.consumptionKnob.max}
                size={80}
                valueColor={knobData.consumptionKnob.valueColor}
                readOnly
              />
              <div>Consumption</div>
            </div>
            <div className="knob">
              <Knob
                value={knobData.productionKnob.vaLue}
                min={knobData.productionKnob.min}
                max={knobData.productionKnob.max}
                size={80}
                valueColor={knobData.productionKnob.valueColor}
                readOnly
              />
              <div>Production</div>
            </div>
            <div className="knob">
              <Knob
                value={knobData.powerToGridKnob.vaLue}
                min={knobData.powerToGridKnob.min}
                max={knobData.powerToGridKnob.max}
                size={80}
                valueColor={knobData.powerToGridKnob.valueColor}
                readOnly
              />
              <div>Power to grid</div>
            </div>
            <div className="knob">
              <Knob
                value={knobData.powerFromGridKnob.vaLue}
                min={knobData.powerFromGridKnob.min}
                max={knobData.powerFromGridKnob.max}
                size={80}
                valueColor={knobData.powerFromGridKnob.valueColor}
                readOnly
              />
              <div>Power from grid</div>
            </div>

            {/*           <div className="knob">
            <Knob value={knobData.batteryTemperature} size={80} readOnly />
            <div>Battery Temperature</div>
          </div>
          <div className="knob">
            <Knob
              value={knobData.outdoorTemperature}
              min={-50}
              max={50}
              size={80}
              readOnly
            />
            <div>Outdoor temperature</div>
          </div> */}
          </div>
        )}
        {!enlargedChart && (
          <div className="grid-container">
            <div className="chart">
              <div
                className="enlarge-chart-button"
                onClick={() => {
                  dispatch(setEnlargedChart(true))
                  setSelectedChart(batteryData)
                }}
              >
                +
              </div>

              <Chart type="line" data={batteryData} options={options} />
            </div>
            <div className="chart">
              <div
                className="enlarge-chart-button"
                onClick={() => {
                  dispatch(setEnlargedChart(true))
                  setSelectedChart(dailySumData)
                }}
              >
                +
              </div>
              <Chart type="line" data={dailySumData} options={options} />
            </div>
            <div className="chart">
              <div
                className="enlarge-chart-button"
                onClick={() => {
                  dispatch(setEnlargedChart(true))
                  setSelectedChart(gridThroughputData)
                }}
              >
                +
              </div>
              <Chart type="line" data={gridThroughputData} options={options} />
            </div>
            <div className="chart">
              <div
                className="enlarge-chart-button"
                onClick={() => {
                  dispatch(setEnlargedChart(true))
                  setSelectedChart(wattThroughputData)
                }}
              >
                +
              </div>
              <Chart type="line" data={wattThroughputData} options={options} />
            </div>
          </div>
        )}
        {enlargedChart && (
          <div className="chart large-chart">
            <div
              className="enlarge-chart-button"
              onClick={() => dispatch(setEnlargedChart(false))}
            >
              -
            </div>
            <Chart type="line" data={selectedChart} options={options} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MainPanel
