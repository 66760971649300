import { RootState } from "../app/store"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AxiosInstance } from "axios"

export type PanelType = "main" | "calendar"

export type UIStates = {
  enlargedChart: boolean
  selectedPanel: PanelType
}

const initialState: UIStates = {
  enlargedChart: false,
  selectedPanel: "main",
}

export const uiStateSlice = createSlice({
  name: "UIState",
  initialState,
  reducers: {
    setEnlargedChart: (state, action: PayloadAction<boolean>) => {
      state.enlargedChart = action.payload
    },
    setSelectedPanel: (state, action: PayloadAction<PanelType>) => {
      state.selectedPanel = action.payload
    },
  },
  extraReducers: (builder) => {},
})

export const getEnlargedChart = (state: RootState) =>
  state.UIState.enlargedChart

export const getSelectedPanel = (state: RootState) =>
  state.UIState.selectedPanel

export const { setEnlargedChart, setSelectedPanel } = uiStateSlice.actions

export default uiStateSlice.reducer
