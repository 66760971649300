import { useEffect, useRef } from "react"
import axios from "axios"
import type { AxiosInstance } from "axios"

const baseUrl = "http://pepi-home.com/api/v1"

export const useAxios = () => {
  const axiosInstance = useRef<AxiosInstance>()

  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL: baseUrl,
      headers: {
        Accept: "application/json",
      },
    })
    return () => {
      axiosInstance.current = undefined
    }
  }, [])

  return axiosInstance
}
