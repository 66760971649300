import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import UIStateReducer from "../features/UIState"
import ChartReducer from "../features/Chart"
import KnobReducer from "../features/Knob"
export const store = configureStore({
  reducer: {
    UIState: UIStateReducer,
    Chart: ChartReducer,
    Knob: KnobReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
