import React, { useEffect, useState } from "react"
import logo from "./logo.svg"
import "./App.css"
import MainPanel from "./components/MainPanel"
import { useAppDispatch, useAppSelector } from "./app/hooks"
import {
  fetchBatteryChartData,
  fetchDailySumChartData,
  fetchGridThroughputChartData,
  fetchWattThroughputChartData,
  getBatteryChartData,
  selectBatteryChartData,
} from "./features/Chart"
import { useAxios } from "./utils/axios"
import { fetchKnobData } from "./features/Knob"

function App() {
  const dispatch = useAppDispatch()
  const batteryData = useAppSelector(selectBatteryChartData)
  const getCurrentDate = () => {
    const currentDate = new Date()

    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0")
    const day = String(currentDate.getDate()).padStart(2, "0")

    const formattedDate = `${year}-${month}-${day}`

    return formattedDate
  }
  const currentDate = getCurrentDate()
  //const currentDate = "2024-12-03"
  const axios = useAxios()

  useEffect(() => {
    if (axios.current) {
      dispatch(
        fetchBatteryChartData({
          axios: axios.current,
          url: `/${currentDate}/battery`,
        })
      )
      dispatch(
        fetchDailySumChartData({
          axios: axios.current,
          url: `/${currentDate}/dailySum`,
        })
      )
      dispatch(
        fetchWattThroughputChartData({
          axios: axios.current,
          url: `/${currentDate}/wattThroughput`,
        })
      )
      dispatch(
        fetchGridThroughputChartData({
          axios: axios.current,
          url: `/${currentDate}/gridThroughput`,
        })
      )
      dispatch(
        fetchKnobData({
          axios: axios.current,
          url: "/current",
        })
      )
    }
  }, [])

  return (
    <div className="App">
      <MainPanel />
    </div>
  )
}

export default App
