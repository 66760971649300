import { RootState } from "../app/store"
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit"
import { AxiosInstance } from "axios"
import {
  Dataset,
  selectDailySumChartData,
  selectGridThroughputChartData,
  selectWattThroughputChartData,
} from "./Chart"

export type Knob = {
  min: number
  max: number
  vaLue: number
}

export type weatherInfo = {
  batteryTemperature: number
  outdoorTemperature: number
  weathercode: number
}

export type knobData = {
  day: string
  consumption: number
  production: number
  powerFromGrid: number
  powerToGrid: number
  batteryPercent: number
  autonomyPercent: number
  batteryTemperature: number
  outdoorTemperature: number
  weatherCode: number
}

export const defaultKnob: Knob = {
  min: 0,
  max: 100,
  vaLue: 0,
}

export const fetchKnobData = createAsyncThunk(
  "knob/fetchKnobData",
  async (data: { axios: AxiosInstance; url: string }) => {
    const response = await data.axios.get(data.url)
    console.log(response.data)
    return response.data
  }
)

/* export const getAllAa = createAsyncThunk(
    
) */

export const knobSlice = createSlice({
  name: "knob",
  initialState: {
    knobData: {} as knobData,
    weatherInfo: {},
    batteryKnob: defaultKnob,
    autonomyKnob: defaultKnob,
    consumptionKnob: defaultKnob,
    productionKnob: defaultKnob,
    powerToGridKnob: defaultKnob,
    powerFromGridKnob: defaultKnob,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchKnobData.fulfilled, (state, action) => {
      console.log(action.payload)
      state.knobData = action.payload
    })
  },
})

export const {} = knobSlice.actions

//export const selectAa = (state: RootState) => state.aa

export const selectAllKnobData = createSelector(
  (state: RootState) => state.Knob.knobData,
  selectWattThroughputChartData,
  selectGridThroughputChartData,
  (data, wattThroughputChartData, gridThroughputChartData) => {
    console.log(wattThroughputChartData)
    console.log(gridThroughputChartData)
    if (!wattThroughputChartData || !gridThroughputChartData) return

    const knobValues = {
      batteryKnob: {
        min: 0,
        max: 100,
        vaLue: data.batteryPercent,
        valueColor: "rgb(91, 194, 54)",
      },
      autonomyKnob: {
        min: 0,
        max: 100,
        vaLue: data.autonomyPercent,
        valueColor: "rgb(166, 42, 168)",
      },
      consumptionKnob: {
        min: 0,
        max: +Math.min(...wattThroughputChartData.datasets[1].data).toFixed(2) * -1,
        vaLue: +data.consumption * -1,
        valueColor: "rgb(220, 53, 69)",
      },
      productionKnob: {
        min: 0,
        max: +Math.max(...wattThroughputChartData.datasets[0].data).toFixed(2),
        vaLue: data.production,
        valueColor: "rgb(255, 200, 0)",
      },
      powerToGridKnob: {
        min: 0,
        max: +Math.min(...gridThroughputChartData.datasets[1].data).toFixed(2),
        vaLue: +data.powerToGrid < 0 ? 0 : data.powerToGrid,
        valueColor: "rgb(0, 210, 255)",
      },
      powerFromGridKnob: {
        min: 0,
        max: +Math.max(...gridThroughputChartData.datasets[0].data).toFixed(2),
        vaLue: data.powerFromGrid,
        valueColor: "rgb(0, 123, 255)",
      },
    }
    console.log(knobValues)
    return knobValues
  }
)

export default knobSlice.reducer
